import Cookies from 'js-cookie';
import actions from './actions';
import { DataService } from '../../config/dataService/dataService';

const { loginSuccess, logoutSuccess } = actions;

const generic500Error = 'Internal Server Error';


const login = data => {
  return async dispatch => {
    try {
      const response = await DataService.post('/api/users/login', data);
      if (response.status === 200) {
        Cookies.set('cf_internal_access_token', response.data.data.token);
        Cookies.set('cf_logedIn', true);
        Cookies.set('cf_user', JSON.stringify(response.data.data.user));

        dispatch(loginSuccess(true));

        return response.data.data;
      }
      return '';
    } catch (err) {
      // alert(generic500Error);
    }
    return '';
  };
};

  /**
   * Removes user data from local storage and cookies and logs out the user.
   *
   * @return {Promise<boolean>} - Resolves with true if the user is logged out successfully.
   */
const logOut = () => {
  const removeStorage = () => {
    Cookies.remove('cf_logedIn');
    Cookies.remove('cf_internal_access_token');
    Cookies.remove('cpress_internal_access_token');
    Cookies.remove('cf_user');
    localStorage.removeItem('cf_user_picture');
  };

  return async dispatch => {
    try {
      await DataService.post('/api/users/logout', {});
      dispatch(logoutSuccess(null));
      removeStorage();

      return true;
    } catch (err) {
      removeStorage();
      return true;
    }
  };
};

  /**
   * Handles a Google login attempt.
   *
   * @param {object} data - Object that holds the Google user data (token, profile, etc).
   * @param {string} picture - The user's profile picture URL.
   *
   * @return {Promise<string>} - Resolves with 'OK' if the user is logged in successfully.
   */
const loginGoogle = (data, picture) => {
  return async dispatch => {
    try {
      const response = await DataService.post('/api/users/googleLogin', data);
      if (response.status >= 200) {
        // Record created
        // console.log('customerSignup.response: ', response.data.data);

        // TODO. Decide if we want to store User object in reducer
        Cookies.set('cf_internal_access_token', response.data.data.token);
        Cookies.set('cpress_internal_access_token', response.data.data.token, {
          domain: (new URL(process.env.REACT_APP_CHEQUEPRESS_URL).host),
          sameSite: 'None',
          secure: true,
        });
        Cookies.set('reseller', response.data.data.token);
        Cookies.set('cf_logedIn', true);
        Cookies.set('cf_user', JSON.stringify(response.data.data.user));
        localStorage.setItem('cf_user_picture', picture);

        dispatch(loginSuccess(true));

        return 'OK';
      } else {
        // Record not created
        //alert(generic500Error);
      }
      return response.statusText;
    } catch (err) {
      //alert(generic500Error);
    }
    return false;
  };
};

const signup = data => {
  return async dispatch => {
    try {
      const response = await DataService.post('/api/users/register', data);
      // console.log('customerSignup.response: ', response);
      if (response.status === 201) return true;
      return false;
    } catch (err) {}
    return false;
  };
};

const enable2fa = () => {
  return async dispatch => {
    try {
      const response = await DataService.post(`/api/user/two-factor-authentication`);
      if (response.status === 200) return true;
      return false;
    } catch (err) {
      alert(generic500Error);
    }
    return false;
  };
};

const challenge2fa = data => {
  return async dispatch => {
    try {
      const response = await DataService.post(`/api/two-factor-challenge`, data);
      if (response.status === 200) {
        // console.log('challenge2fa.user: ', response.data.data.user);
        dispatch(loginSuccess(true));
        Cookies.set('cf_internal_access_token', response.data.data.token);
        Cookies.set('cf_logedIn', true);
        Cookies.set('cf_user', JSON.stringify(response.data.data.user));
        return true;
      }
      return false;
    } catch (err) {
      alert(generic500Error);
    }
    return false;
  };
};

const forgotPassword = data => {
  return async dispatch => {
    try {
      const response = await DataService.post('/api/users/forgot-password', data);
      if (response.status === 200) return true;
      return false;
    } catch (err) {
      alert(generic500Error);
    }
    return false;
  };
};

const resetPassword = data => {
  return async dispatch => {
    try {
      const response = await DataService.put(`/api/users/setPassword/${data.token}`, data);
      if (response.status === 200) return true;
      return false;
    } catch (err) {
      alert(generic500Error);
    }
    return false;
  };
};

export { login, loginGoogle, logOut, signup, enable2fa, challenge2fa, forgotPassword, resetPassword };
