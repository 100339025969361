import React, { useEffect, useState } from 'react';
import { hot } from 'react-hot-loader/root';
import { Provider, useSelector } from 'react-redux';
import { ThemeProvider } from 'styled-components';
import { BrowserRouter as Router, Redirect, Route } from 'react-router-dom';
import store from './redux/store';
import Admin from './routes/admin';
import Ecommerce from './routes/ecommerce';
import Auth from './routes/auth';
import './static/css/style.css';
import config from './config/config';
import ProtectedRoute from './components/utilities/protectedRoute';
import AlertResponse from './container/alertResponse/AlertResponse';
import { GoogleOAuthProvider } from '@react-oauth/google';
import NotFound from './container/404';

const REACT_APP_AUTH_DOMAIN = process.env.REACT_APP_AUTH_DOMAIN;
const REACT_APP_RESELLER_AUTH_DOMAIN = process.env.REACT_APP_RESELLER_AUTH_DOMAIN;

const { theme } = config;

const ProviderConfig = () => {
  const { isLoggedIn, darkMode } = useSelector(state => {
    return {
      darkMode: state.ChangeLayoutMode.data,
      isLoggedIn: state.auth.login,
    };
  });

  const [path, setPath] = useState(window.location.pathname);
  const [subdomain] = useState(window.location.origin);

  useEffect(() => {
    let unmounted = false;
    if (!unmounted) {
      setPath(window.location.pathname);
    }    
    // eslint-disable-next-line no-return-assign
    return () => (unmounted = true);
  }, [setPath]);

  return (
    <ThemeProvider theme={{ ...theme, darkMode }}>
      <Router basename={process.env.PUBLIC_URL}>
        {
          <AlertResponse /> // Alert of response
        }

        <>
          {subdomain === REACT_APP_AUTH_DOMAIN ||
          subdomain === REACT_APP_RESELLER_AUTH_DOMAIN ||
          (subdomain === 'https://www.qplus.app' && path.startsWith('/auth')) ||
          path.startsWith('/admin') ? (
            <>
              <GoogleOAuthProvider clientId="5414141657-c732msfn60h9iil8505ag1891qjb763t.apps.googleusercontent.com">
                <Route path="/auth" component={Auth} />
              </GoogleOAuthProvider>

              {isLoggedIn && <ProtectedRoute path="/admin" component={Admin} />}
              {isLoggedIn && path === '/' && <Redirect to="/admin" />}

              {!isLoggedIn && path.startsWith('/admin') && <Redirect to="/auth" />}
              {!isLoggedIn && path.startsWith('/') && !path.startsWith('/auth/reset-password/') && (
                <Redirect to="/auth" />
              )}
            </>
          ) : (
            <>
              {path.startsWith('/auth') || path.startsWith('/admin') ? <Redirect to="/404" /> : <></>}

              {path.startsWith('/404') ? (
                <Route exact path="/404">
                  <NotFound />
                </Route>
              ) : (
                <Route path="/" component={Ecommerce} />
              )}
            </>
          )}
        </>
      </Router>
    </ThemeProvider>
  );
};

function App() {
  return (
    <Provider store={store}>
      <ProviderConfig />
    </Provider>
  );
}

export default hot(App);
